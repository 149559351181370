import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { history } from "utils/history";
import decode from "jwt-decode";

interface IInitialState {
  isSigningIn: boolean;
  dashboardOrScheduling: boolean;
}

const initialState: IInitialState = {
  isSigningIn: false,
  dashboardOrScheduling: false,
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setIsSigningIn: (state, { payload }: PayloadAction<boolean>) => {
      state.isSigningIn = payload;
    },
    setDashboardOrScheduling: (state, { payload }: PayloadAction<boolean>) => {
      state.dashboardOrScheduling = payload;
    }
  },
});

export const doSignin = ({
  email,
  password,
}: {
  email: string;
  password: string;
}): AppThunk => async (dispatch: AppDispatch) => {
  const { setIsSigningIn , setDashboardOrScheduling} = authSlice.actions;
  dispatch(setIsSigningIn(true));
  try {
    const response = await api.post("/api/signin", {
      email,
      senha: password,
    });
    localStorage.setItem("@token", response.data.data.token);
    localStorage.setItem("@refresh_token", response.data.data.refresh_token);

    //Company data
    localStorage.setItem("@idtenant", response.data.data.idtenant);
    localStorage.setItem("@company_name", response.data.data.company.nome);
    localStorage.setItem("@company_logo", response.data.data.company.logo_base64);

    if (response.data.data.user.cargo) {
      toast.success("Bem-vindo(a)", toastOptions);
    } else {
      toast.error("Não foi possível autenticar", toastOptions);
    }
    const decodedToken: any = decode(response.data.data.token);

    if(decodedToken.cargo.agendamentos && decodedToken.cargo.dashboard){
    dispatch(setDashboardOrScheduling(true))
    // return dispatch(setIsSigningIn(false));
    }
    history.push("/");
    dispatch(setIsSigningIn(false));
  } catch (error) {
    dispatch(setIsSigningIn(false));
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const {
  setIsSigningIn: setIsSigningInActionCreator,
} = authSlice.actions;

export default authSlice.reducer;
