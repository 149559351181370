import axios from "axios";
import io from "socket.io-client";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";

// const apiUrl = "http://localhost:8000/";
// const socketUrl = "wss://websocket.drclick.com.br"
const apiUrl = process.env.REACT_APP_API_URL; // process.env.REACT_APP_API_URL http://apidev.maissaude.bitx.com.br | https://api.dev.drclick.com.br/ | http://treinamento.api.drclick.com.br/ | http://localhost:8000/
const apiUrlNf = process.env.REACT_APP_API_URL_NF; // http://apidev.maissaude.bitx.com.br
// const socketUrl = process.env.SOCKET_URL; //ws://apidev.maissaude.bitx.com.br
//  const apiUrl = "http://localhost:3007"
//  const socketUrl = "ws://localhost:3007"
// https://api.viver.drclick.com.br

// const socket = io.connect(socketUrl);

const apiUrlSchedule = process.env.REACT_APP_SCHEDULE_API_URL;

const apiSchedule = axios.create({
  baseURL: apiUrlSchedule,
  responseType: "json",
});

const api = axios.create({
  baseURL: apiUrl,
  responseType: "json",
});

const apiNf = axios.create({
  baseURL: apiUrlNf,
  responseType: "json",
});

apiSchedule.interceptors.request.use(
  (config) => {
    // perform a task before the request is sent
    const token = localStorage.getItem("@token");
    config.headers.Authorization = `Bearer ${token}`;

    const idtenant = localStorage.getItem("@idtenant");

    if (idtenant)
      config.headers.idtenant = `${idtenant}`;

    return config;
  },
  (error) => {
    // handle the error
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  (config) => {
    // perform a task before the request is sent
    const token = localStorage.getItem("@token");
    config.headers.Authorization = `Bearer ${token}`;

    const idtenant = localStorage.getItem("@idtenant");

    if (idtenant)
      config.headers.idtenant = `${idtenant}`;

    return config;
  },
  (error) => {
    // handle the error
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let failedRequestQueue = [];

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 423) {
      if (error.response.data?.error.code === "auth/user-not_authorized") {
        const refresh_token_ls = localStorage.getItem("@refresh_token");
        const originalConfig = error.config;

        if (!isRefreshing) {
          isRefreshing = true;

          api
            .post("/api/refresh", {
              refresh_token: refresh_token_ls,
            })
            .then((response) => {
              const { token, refresh_token } = response.data.data;

              localStorage.setItem("@token", token);
              localStorage.setItem("@refresh_token", refresh_token);

              api.defaults.headers["Authorization"] = `Bearer ${token}`;

              failedRequestQueue.forEach((request) => request.onSuccess(token));
              failedRequestQueue = [];
            })
            .catch((err) => {
              failedRequestQueue.forEach((request) => request.onFailure(err));
              failedRequestQueue = [];

              if (process.browser) {
                localStorage.removeItem("@token");
                localStorage.removeItem("@refresh_token");
                window.location.href = "/auth/signin";
              }
            })
            .finally(() => {
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestQueue.push({
            onSuccess: (token) => {
              originalConfig.headers["Authorization"] = `Bearer ${token}`;

              resolve(api(originalConfig));
            },
            onFailure: (err) => {
              reject(err);
            },
          });
        });
      } else {
        if (process.browser) {
          localStorage.removeItem("@token");
          localStorage.removeItem("@refresh_token");
          window.location.href = "/auth/signin";
        } else {
          return Promise.reject(
            toast.error(
              "Sessão expirada, favor realizar o login novamente",
              toastOptions
            )
          );
        }
      }
    }

    return Promise.reject(error);
  }
);

export { /*socket, socketUrl,*/ apiUrl, apiNf, apiSchedule };
export default api;
