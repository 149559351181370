import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store";
import { ISelectOption, ISpecialties } from "pages/SchedulePage";
import api, { apiSchedule } from "utils/API";
import { toast } from "react-toastify";
import { IFilter } from "models/shared";
import dayjs from "dayjs";
import { fetchProfessionalsByServiceCategory } from "../ProfessionalsReducer";
import { updateServiceItemsSchedulePageFilter } from "../ServiceItemsReducer";
import { clearAppointments } from "../AppointmentsReducer";
import {
  setDisponibilityMapCurrentHealthPlan,
  setDisponibilityMapProfessionals,
  setDisponibilityMapServiceCategories,
  setDisponibilityMapServiceItems,
  updateDisponibilityMapFilterArray,
} from "../DisponibilityMapReducer";
import toastOptions from "utils/toastOptions";
import { IAppointment } from "models/Appointments";

// * Lida com controle da interface da página de schedule '/schedule'

export interface ISelectPatient {
  label: string;
  value: string;
  idpaciente: string;
  datanascimento?: string;
  nomesocial?: string;
}

export interface SocketCard {
  idprofissional: string;
  date: Date;
  times: string;
  idagenda: string;
  expiresAt: Date;
}

interface IInitialState {
  isDisponibilityMapDialogOpen: boolean;
  isNewAppointmentDialogOpen: boolean;
  schedulePageFilterArray: IFilter[];
  schedulePagePossibleStatuses: string[];
  schedulePageCurrentClinics: ISelectOption[];
  schedulePageCurrentProfessionals: ISelectOption[];
  schedulePageCurrentPatient: string;
  schedulePageCurrentServiceCategories: ISelectOption[];
  schedulePageCurrentSereviceItems: ISelectOption[];
  schedulePageCurrentHealthPlan: ISelectOption;
  currentDate: Date | string | number;
  currentPatient: ISelectPatient;
  scheduleItemStatusArray: string[];
  scheduleItemAvailability: string; //"all" | "available" | "unavailable";
  remarcacao: boolean;
  idConsultaRemarcacao: string;
  checkinRemarcacao: boolean;
  quoteDialogOpen: boolean;
  isPatientTimelineDialogOpen: boolean;
  socketCards: SocketCard[];
  cardPoolingId: number;
  intervalFecthId: number;
  selectedScheduleId: string;
  modalHoverSchedule: boolean;
  effectRequestMap: boolean;
  dataModalHoverSchedule: any;
  modalHoverScheduleProfessionalTime: boolean;
  dataModalHoverScheduleProfessionalTime: any;
  modalHoverScheduleProfessional: boolean;
  dataModalHoverScheduleProfessional: any;
  modalResumeAndCheckin: boolean;
  dataModalResumeAndCheckin: any;
  blockPatientSearch: boolean;
  remarcacaoOpenModal: boolean;
  remarcacaoChoiceDay: boolean;
  appointmentReschedule: IAppointment | null;
  remarcacaoTimeLine: boolean;
  clearPopups: boolean;
  timelineFlow: boolean;
  isFilterStatus: boolean;
  isSchedulingCombo: boolean;
  schedulingComboId: string;
  specialties: ISpecialties[] | null;
}

const initialState: IInitialState = {
  isDisponibilityMapDialogOpen: false,
  isNewAppointmentDialogOpen: false,
  schedulePageFilterArray: [
    { key: "idclinica", value: null },
    { key: "idcategoriaservico", value: null },
    { key: "idprofissional", value: null },
    { key: "iditemservico", value: null },
    { key: "paciente", value: null },
    { key: "idconvenio", value: null },
    { key: "idplano", value: null },
    { key: "search", value: null },
  ],
  schedulePagePossibleStatuses: [],
  schedulePageCurrentClinics: [],
  schedulePageCurrentProfessionals: [],
  schedulePageCurrentServiceCategories: [],
  schedulePageCurrentSereviceItems: [],
  schedulePageCurrentHealthPlan: { label: "", value: "" },
  currentDate: dayjs(new Date()).toString(),
  currentPatient: {
    value: "",
    label: "",
    idpaciente: "",
    datanascimento: "",
  },
  scheduleItemStatusArray: [
    "agendado",
    "confirmado",
    "quitado",
    "checkin",
    "atendido",
    "cancelado",
    "faltou",
  ],
  scheduleItemAvailability: "all",
  remarcacao: false,
  remarcacaoChoiceDay: false,
  remarcacaoOpenModal: false,
  idConsultaRemarcacao: "",
  checkinRemarcacao: false,
  quoteDialogOpen: false,
  isPatientTimelineDialogOpen: false,
  socketCards: [],
  schedulePageCurrentPatient: "",
  cardPoolingId: -1,
  intervalFecthId: -1,
  selectedScheduleId: "",
  modalHoverScheduleProfessional: false,
  dataModalHoverScheduleProfessional: {},
  modalHoverScheduleProfessionalTime: false,
  dataModalHoverScheduleProfessionalTime: {},
  modalHoverSchedule: false,
  dataModalHoverSchedule: {},
  modalResumeAndCheckin: false,
  dataModalResumeAndCheckin: {},
  blockPatientSearch: false,
  effectRequestMap: false,
  appointmentReschedule: null,
  remarcacaoTimeLine: false,
  clearPopups: true,
  timelineFlow: false,
  isFilterStatus: false,
  isSchedulingCombo: false,
  schedulingComboId: "",
  specialties: null
};

const schedulePageSlice = createSlice({
  name: "schedulePageSlice",
  initialState,
  reducers: {
    setRemarcacaoTimeLine: (state, { payload }: PayloadAction<boolean>) => {
      state.remarcacaoTimeLine = payload;
    },
    setAppointmentReschedule: (
      state,
      { payload }: PayloadAction<IAppointment | null>
    ) => {
      state.appointmentReschedule = payload;
    },
    setRemarcacaoOpenModal: (state, { payload }: PayloadAction<boolean>) => {
      state.remarcacaoOpenModal = payload;
    },
    setRemarcacaoChoiceDay: (state, { payload }: PayloadAction<boolean>) => {
      state.remarcacaoChoiceDay = payload;
    },
    setTimelineFlow: (state, { payload }: PayloadAction<boolean>) => {
      state.timelineFlow = payload;
    },
    setClearPopups: (state, { payload }: PayloadAction<boolean>) => {
      state.clearPopups = payload;
    },
    setIsFilterStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.isFilterStatus = payload;
    },
    setEffectRequestMap: (state) => {
      state.effectRequestMap = !state.effectRequestMap;
    },
    setModalResumeAndCheckin: (state, { payload }: PayloadAction<boolean>) => {
      state.modalResumeAndCheckin = payload;
    },
    setDataModalResumeAndCheckin: (state, { payload }: PayloadAction<any>) => {
      state.dataModalResumeAndCheckin = payload;
    },
    setModalHoverScheduleProfessinalTime: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.modalHoverScheduleProfessionalTime = payload;
    },
    setDataModalHoverScheduleProfessinalTime: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.dataModalHoverScheduleProfessionalTime = payload;
    },
    setModalHoverScheduleProfessinal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.modalHoverScheduleProfessional = payload;
    },
    setDataModalHoverScheduleProfessinal: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.dataModalHoverScheduleProfessional = payload;
    },
    setModalHoverSchedule: (state, { payload }: PayloadAction<boolean>) => {
      state.modalHoverSchedule = payload;
    },
    setDataModalHoverSchedule: (state, { payload }: PayloadAction<any>) => {
      state.dataModalHoverSchedule = payload;
    },
    setIsDisponibilityMapDialogOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDisponibilityMapDialogOpen = payload;
    },
    setIsNewAppointmentDialogOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isNewAppointmentDialogOpen = payload;
    },
    setSchedulePageServiceCategories: (
      state,
      { payload }: PayloadAction<ISelectOption[]>
    ) => {
      state.schedulePageCurrentServiceCategories = payload;
    },
    setSocketCards: (state, { payload }: PayloadAction<SocketCard[]>) => {
      state.socketCards = payload;
    },
    setCardPoolingId: (state, { payload }: PayloadAction<number>) => {
      state.cardPoolingId = payload;
    },
    setIntervalFecthId: (state, { payload }: PayloadAction<number>) => {
      state.intervalFecthId = payload;
    },
    setSchedulePageCheckedStatusesAdd: (
      state,
      {
        payload: { statusName },
      }: PayloadAction<{
        statusName: string;
      }>
    ) => {
      state.schedulePagePossibleStatuses.push(statusName);
    },
    setSchedulePageCheckedStatusesRemove: (
      state,
      {
        payload: { statusName },
      }: PayloadAction<{
        statusName: string;
      }>
    ) => {
      state.schedulePagePossibleStatuses =
        state.schedulePagePossibleStatuses.filter(
          (nomestatus) => nomestatus !== statusName
        );
    },
    setSchedulePageClinics: (
      state,
      { payload }: PayloadAction<ISelectOption[]>
    ) => {
      state.schedulePageCurrentClinics = payload;
    },
    setSchedulePageProfessionals: (
      state,
      { payload }: PayloadAction<ISelectOption[]>
    ) => {
      state.schedulePageCurrentProfessionals = payload;
    },
    setSchedulePagePatient: (state, { payload }: PayloadAction<string>) => {
      state.schedulePageCurrentPatient = payload;
    },
    setSchedulepageserviceItems: (
      state,
      { payload }: PayloadAction<ISelectOption[]>
    ) => {
      state.schedulePageCurrentSereviceItems = payload;
    },
    setSchedulePageHealthPlans: (
      state,
      { payload }: PayloadAction<ISelectOption>
    ) => {
      state.schedulePageCurrentHealthPlan = payload;
    },
    setSpecialties: (state, { payload }: PayloadAction<ISpecialties[] | null>) => {
      state.specialties = payload;
    },
    updateSchedulePageFilterArray: (
      state,
      { payload }: PayloadAction<IFilter>
    ) => {
      const index = state.schedulePageFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      if (state.schedulePageFilterArray[index]) {
        state.schedulePageFilterArray[index].value = payload.value;
      }
    },
    updateMultiSchedulePageFilterArray: (
      state,
      { payload }: PayloadAction<IFilter[]>
    ) => {
      payload.forEach((filter) => {
        const index = state.schedulePageFilterArray.findIndex(
          (item) => item.key === filter.key
        );
        if (state.schedulePageFilterArray[index]) {
          state.schedulePageFilterArray[index].value = filter.value;
        }
      });
    },
    transferFilterFromDisponibilityMap: (
      state,
      {
        payload: { items, categories, clinics },
      }: PayloadAction<{
        items: ISelectOption[];
        categories: ISelectOption[];
        clinics: ISelectOption[];
      }>
    ) => {
      state.schedulePageCurrentSereviceItems = items;
      state.schedulePageCurrentServiceCategories = categories;
      const professionals = state.schedulePageFilterArray.find(
        (item) => item.key === "idprofissional"
      )?.value;

      state.schedulePageFilterArray = [
        { key: "idprofissional", value: professionals },
        { key: "idclinica", value: clinics.map((c) => c.value) },
        { key: "idcategoriaservico", value: categories.map((c) => c.value) },
        { key: "iditemservico", value: items.map((i) => i.value) },
      ];
    },
    setSchedulePageCurrentDate: (state, { payload }: PayloadAction<Date | string>) => {
      state.currentDate = payload;
    },
    setSchedulePageCurrentPatient: (
      state,
      { payload }: PayloadAction<ISelectPatient>
    ) => {
      state.currentPatient = payload;
    },
    setScheduleItemStatusArray: (state, { payload }: PayloadAction<string>) => {
      const newArray = state.scheduleItemStatusArray;
      const index = newArray.findIndex((status) => status === payload);
      if (index === -1) {
        state.scheduleItemStatusArray = [...newArray, payload];
      } else {
        state.scheduleItemStatusArray = newArray.filter(
          (status) => status !== payload
        );
      }
    },
    setScheduleItemAvailability: (
      state,
      { payload }: PayloadAction<string> //"all" | "available" | "unavailable"
    ) => {
      state.scheduleItemAvailability = payload;
    },
    setRemarcacao: (state, { payload }: PayloadAction<boolean>) => {
      state.remarcacao = payload;
    },
    setIdConsultaRemarcacao: (state, { payload }: PayloadAction<string>) => {
      state.idConsultaRemarcacao = payload;
    },
    setCheckinRemarcacao: (state, { payload }: PayloadAction<boolean>) => {
      state.checkinRemarcacao = payload;
    },
    setQuoteDialogOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.quoteDialogOpen = payload;
    },
    setIsPatientTimelineDialogOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isPatientTimelineDialogOpen = payload;
    },
    setSelectedScheduleId: (state, { payload }: PayloadAction<string>) => {
      state.selectedScheduleId = payload;
    },
    setBlockPatientSearch: (state, { payload }: PayloadAction<boolean>) => {
      state.blockPatientSearch = payload;
    },
    setIsSchedulingCombo: (state, { payload }: PayloadAction<boolean>) => {
      state.isSchedulingCombo = payload;
    },
    setSchedulingComboId: (state, { payload }: PayloadAction<string>) => {
      state.schedulingComboId = payload;
    },
  },
});

export const resetClinicTimetableFilters =
  (): AppThunk => async (dispatch, getState) => {
    dispatch(
      updateMultiSchedulePageFilterArray([
        {
          key: "idcategoriaservico",
          value: [],
        },
        {
          key: "idcategories",
          value: [],
        },
        {
          key: "iditemservico",
          value: [],
        },
        {
          key: "idprofissional",
          value: [],
        },
        {
          key: "idconvenio",
          value: "",
        },
        {
          key: "idplano",
          value: "",
        },
      ])
    );

    dispatch(setSchedulePageServiceCategories([]));
    dispatch(fetchProfessionalsByServiceCategory([]));
    dispatch(setSchedulepageserviceItems([]));
    // dispatch(setSchedulePageClinics([]));
    dispatch(setSchedulePageProfessionals([]));
    dispatch(
      updateServiceItemsSchedulePageFilter({
        key: "idprofessionals",
        value: [],
      })
    );
    dispatch(
      updateServiceItemsSchedulePageFilter({
        key: "search",
        value: "",
      })
    );
    dispatch(clearAppointments());
    dispatch(setSchedulePagePatient(""));
    dispatch(setSchedulePageHealthPlans({ label: "", value: "" }));
  };

export const resetDisponibilityMapFilters =
  (): AppThunk => async (dispatch, getState) => {
    dispatch(setDisponibilityMapServiceCategories([]));
    dispatch(setDisponibilityMapServiceItems([]));
    dispatch(setDisponibilityMapProfessionals([]));
    dispatch(setDisponibilityMapCurrentHealthPlan({ label: "", value: "" }));
    //dispatch(resetDisponibilityMapFilterArray());

    dispatch(
      updateDisponibilityMapFilterArray({
        key: "iditemservico",
        value: [],
      })
    );
    dispatch(
      updateDisponibilityMapFilterArray({
        key: "idprofissional",
        value: [],
      })
    );
    dispatch(
      updateDisponibilityMapFilterArray({
        key: "idcategoriaservico",
        value: [],
      })
    );
    dispatch(
      updateDisponibilityMapFilterArray({
        key: "idconvenio",
        value: "",
      })
    );
    dispatch(
      updateDisponibilityMapFilterArray({
        key: "idplano",
        value: "",
      })
    );

    dispatch(setDisponibilityMapCurrentHealthPlan({ label: "", value: "" }));
  };

export const syncDisponibilityMapWithScheduleFilters =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState();

    const {
      schedulePageFilterArray,
      schedulePageCurrentServiceCategories,
      schedulePageCurrentProfessionals,
      schedulePageCurrentSereviceItems,
    } = state.schedulePage;

    dispatch(
      setDisponibilityMapServiceCategories(schedulePageCurrentServiceCategories)
    );
    dispatch(setDisponibilityMapServiceItems(schedulePageCurrentSereviceItems));
    dispatch(
      setDisponibilityMapProfessionals(schedulePageCurrentProfessionals)
    );

    const iditemservico = schedulePageFilterArray.filter((item) => {
      return item.key === "iditemservico" && item.value != null;
    });

    dispatch(
      updateDisponibilityMapFilterArray({
        key: "iditemservico",
        value: iditemservico.map((item) => {
          return item.value;
        }),
      })
    );

    const idprofissional = schedulePageFilterArray.filter((item) => {
      return item.key === "idprofissional" && item.value != null;
    });

    dispatch(
      updateDisponibilityMapFilterArray({
        key: "idprofissional",
        value: idprofissional.map((item) => {
          return item.value;
        }),
      })
    );

    const idcategoriaservico = schedulePageFilterArray.filter((item) => {
      return item.key === "idcategoriaservico" && item.value != null;
    });

    dispatch(
      updateDisponibilityMapFilterArray({
        key: "idcategoriaservico",
        value: idcategoriaservico.map((item) => {
          return item.value;
        }),
      })
    );
  };

export const startSchedulePageCardPooling =
  (intervalMiliseconds = 120000): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { cardPoolingId } = state.schedulePage;

    const { setCardPoolingId } = schedulePageSlice.actions;

    dispatch(clearSchedulePageCardPooling());

    var _tid = setInterval(() => {
      const location = window.location.href.split("/");
      const inSchedulePage = location[location.length - 1].includes("schedule");

      if (inSchedulePage) {
        dispatch(fetchCardStatus());
      } else {
        dispatch(clearSchedulePageCardPooling());
      }
    }, intervalMiliseconds);

    dispatch(setCardPoolingId(_tid));
  };

export const clearSchedulePageCardPooling =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const { cardPoolingId, intervalFecthId } = state.schedulePage;

    if (cardPoolingId) {
      clearInterval(cardPoolingId);
    }

    if (intervalFecthId) {
      clearInterval(intervalFecthId);
    }
  };

export const fetchCardStatus = (): AppThunk => async (dispatch, getState) => {
  try {
    const response = await api.get(`api/cardstatus/all`);

    if (!response.data.data) {
      return console.log("Error on get cardstatus");
    }

    const { setSocketCards } = schedulePageSlice.actions;

    const cards = response.data.data as any[];

    if (cards && cards.length > 0) {
      const expiredAt = dayjs(new Date()).add(60, "second").toDate();

      const updatedCards = cards.map((card: any) => {
        return {
          idagenda: card.idagenda,
          idprofissional: card.idprofissional,
          date: card.date,
          times: card.times,
          expiresAt: expiredAt,
        };
      });

      dispatch(setSocketCards(updatedCards));

      //Verify All Socket Cards
      // const now = new Date();

      // const newSocketCards = socketCards.filter(socketCard => {
      //   if (socketCard.expiresAt < now) {
      //     return false;
      //   }

      //   return true;
      // });

      // dispatch(setSocketCards(newSocketCards.concat(updatedCards)));
    } else {
      dispatch(setSocketCards([]));
    }
  } catch (error: any) {
    if (error.response) {
      toast.error(error.response.data?.error?.message, toastOptions);
    } else {
      console.log(error.message);
    }
  }
};

export const fetchSpecialties =
  (clinicId: string, professionalId: string): AppThunk =>
    async (dispatch) => {
      const { setSpecialties } = schedulePageSlice.actions;
      // dispatch(setisFetchingUsers(true));
      try {
        const query = `?clinicId=${clinicId}&professionalId=${professionalId}`
        const response = await apiSchedule.get(`/schedule/specialties${query}`);
        console.log('response reducer specialties', response.data)
        dispatch(setSpecialties(response.data));
        // dispatch(setCurrentUser(response.data.data));
        // dispatch(setisFetchingUsers(false));
      } catch (error: any) {
        // dispatch(setCurrentUser(null));
        // dispatch(setisFetchingUsers(false));
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      }
    };

export const {
  setIsDisponibilityMapDialogOpen,
  setIsNewAppointmentDialogOpen,
  setSchedulePageServiceCategories,
  setSchedulepageserviceItems,
  setSchedulePageProfessionals,
  setSchedulePageClinics,
  setSchedulePagePatient,
  updateSchedulePageFilterArray,
  updateMultiSchedulePageFilterArray,
  transferFilterFromDisponibilityMap,
  setSchedulePageCurrentDate,
  setSchedulePageCurrentPatient,
  setScheduleItemStatusArray,
  setScheduleItemAvailability,
  setSchedulePageCheckedStatusesAdd,
  setSchedulePageCheckedStatusesRemove,
  setSelectedScheduleId,
  setRemarcacao,
  setIdConsultaRemarcacao,
  setCheckinRemarcacao,
  setQuoteDialogOpen,
  setClearPopups,
  setTimelineFlow,
  setIsPatientTimelineDialogOpen,
  setSocketCards,
  setCardPoolingId,
  setIntervalFecthId,
  setSchedulePageHealthPlans,
  setModalHoverSchedule,
  setDataModalHoverSchedule,
  setModalHoverScheduleProfessinal,
  setDataModalHoverScheduleProfessinal,
  setModalHoverScheduleProfessinalTime,
  setDataModalHoverScheduleProfessinalTime,
  setModalResumeAndCheckin,
  setDataModalResumeAndCheckin,
  setBlockPatientSearch,
  setEffectRequestMap,
  setRemarcacaoChoiceDay,
  setAppointmentReschedule,
  setRemarcacaoOpenModal,
  setRemarcacaoTimeLine,
  setIsFilterStatus,
  setIsSchedulingCombo,
  setSchedulingComboId,
  setSpecialties,
} = schedulePageSlice.actions;

export default schedulePageSlice.reducer;
