import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, AppDispatch } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import { IUser } from "models/User";
import { IFilter } from "models/shared";
import { ILab } from "models/Lab";
import { IProfessionalExternal } from "components/ModalProfessionalExternal/Form.types";

interface IProfessionalsAndPartnersByItem {
  iditemservico: string;
  professionals: IUser[];
  laboratories: ILab[];
}
interface IInitialState {
  isFetchingProfessionals: boolean;
  isFetchingProfessionalsByServiceItems: boolean;
  isFetchingProfessionalServiceCategories: boolean;
  professionalsSelectOptions: { name: string; id: string; hasSchedule?: boolean }[];
  professionalServiceCategoriesOptions: { id: string; name: string }[];
  professionals: IUser[] | null;
  professionalsFilteredByItem: IUser[] | null;
  professionalsAndProvidersFilteredByItem: IProfessionalsAndPartnersByItem[] | null
  filterArray: IFilter[];
  page: number;
  total: number;
  professionalsSelect: IUser[] | null;
  professionalsExterno: IProfessionalExternal[];
  professionalExternoById: any | null;
}

const initialState: IInitialState = {
  isFetchingProfessionals: false,
  isFetchingProfessionalsByServiceItems: false,
  isFetchingProfessionalServiceCategories: false,
  professionalsSelectOptions: [],
  professionalServiceCategoriesOptions: [],
  professionals: null,
  professionalsFilteredByItem: null,
  professionalsAndProvidersFilteredByItem: null,
  filterArray: [{ key: "idcategoriaservico", value: null }],
  page: 0,
  total: 0,
  professionalsSelect: [],
  professionalsExterno: [],
  professionalExternoById: null
};

const professionalsSlice = createSlice({
  name: "professionalsSlice",
  initialState,
  reducers: {
    setisFetchingProfessionals: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingProfessionals = payload;
    },
    setisFetchingProfessionalsByServiceItems: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingProfessionalsByServiceItems = payload;
    },
    setisFetchingProfessionalServiceCategories: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingProfessionalServiceCategories = payload;
    },
    setProfessionals: (
      state,
      { payload: { users, total, page } }: PayloadAction<{ users: IUser[]; total: number; page: number }>
    ) => {
      state.professionals = users;
      state.total = total;
      state.page = page;
    },
    setProfessionalsFilteredByItem: (state, { payload }: PayloadAction<IUser[]>) => {
      state.professionalsFilteredByItem = payload;
    },
    setProfessionalsAndProvidersFilteredByItem: (state, { payload }: PayloadAction<IProfessionalsAndPartnersByItem[]>) => {
      state.professionalsAndProvidersFilteredByItem = payload;
    },
    setProfessionalsSelectOptions: (state, { payload }: PayloadAction<{ name: string; id: string }[]>) => {
      state.professionalsSelectOptions = payload;
    },
    setProfessionalServiceCategoriesOptions: (state, { payload }: PayloadAction<{ name: string; id: string }[]>) => {
      state.professionalServiceCategoriesOptions = payload;
    },
    setProfessionalsSelect: (state, {payload}: PayloadAction<IUser[]>) => {
      state.professionalsSelect = payload;
    },
    setProfessionalsExterno: (state, {payload}: PayloadAction<any[]>) => {
      state.professionalsExterno = payload;
    },
    setProfessionalExternoById: (state, {payload}: PayloadAction<any>) => {
      state.professionalExternoById = payload;
    },
  },
});

export const fetchProfessionals =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch) => {
    const { setisFetchingProfessionals, setProfessionals } = professionalsSlice.actions;
    dispatch(setisFetchingProfessionals(true));
    try {
      // const { filterArray } = state.users;
      // const queryParameters = queryStringFromFilterArray(filterArray);
      const response = await api.get(`/api/users?page=${page}&limit=${limit}&profissional=true`);
      dispatch(setProfessionals(response.data.data));
      dispatch(setisFetchingProfessionals(false));
    } catch (error: any) {
      dispatch(setisFetchingProfessionals(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchWithProfessionals =
  ({ page = 1, limit = 6 }: { page?: number; limit?: number }): AppThunk =>
  async (dispatch) => {
    const { setisFetchingProfessionals, setProfessionals } = professionalsSlice.actions;
    dispatch(setisFetchingProfessionals(true));
    try {
      // const { filterArray } = state.users;
      // const queryParameters = queryStringFromFilterArray(filterArray);
      const response = await api.get(`/api/users-professionals?page=${page}&limit=${limit}&profissional=true`);
      dispatch(setProfessionals(response.data.data));
      dispatch(setisFetchingProfessionals(false));
    } catch (error: any) {
      dispatch(setisFetchingProfessionals(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };


export const fetchProfessionalsByServiceItems =
  ({ page = 1, limit = 6, serviceItemId }: { page?: number; limit?: number; serviceItemId: string[] }): AppThunk =>
  async (dispatch) => {
    const { setisFetchingProfessionalsByServiceItems, setProfessionalsFilteredByItem } = professionalsSlice.actions;
    dispatch(setisFetchingProfessionalsByServiceItems(true));
    try {
      const response = await api.get(`/api/professionals?iditemservico=${serviceItemId}`);
      dispatch(setProfessionalsFilteredByItem(response.data.data));
      dispatch(setisFetchingProfessionalsByServiceItems(false));
    } catch (error: any) {
      dispatch(setisFetchingProfessionalsByServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchProfessionalsAndPartnersByServiceItems =
  ({ serviceItemId }: { serviceItemId: string[] }): AppThunk =>
  async (dispatch) => {
    const { setisFetchingProfessionalsByServiceItems, setProfessionalsAndProvidersFilteredByItem } = professionalsSlice.actions;
    dispatch(setisFetchingProfessionalsByServiceItems(true));
    try {
      const response = await api.get(`/api/professionals-and-partners?iditemservico=${serviceItemId}`);

      dispatch(setProfessionalsAndProvidersFilteredByItem(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setisFetchingProfessionalsByServiceItems(false));
    }
  };

export const fetchProfessionalsExternal = (): AppThunk =>
  async (dispatch) => {
   const { setisFetchingProfessionals, setProfessionalsExterno } = professionalsSlice.actions;
   const response = await api.get(
     `/api/users-external`
   );
   dispatch(setProfessionalsExterno(response.data.data.users_external));
   dispatch(setisFetchingProfessionals(false));
 }

 export const createProfessionalsExternal = (data:any, cb: Function): AppThunk =>
  async (dispatch) => {
  const { setisFetchingProfessionals } = professionalsSlice.actions;
   try{
    const response = await api.post(
      `/api/users-external`, data
    );
    cb()
    dispatch(fetchProfessionalsExternal())
    toast.success("Criado com sucesso!", toastOptions);
    dispatch(setisFetchingProfessionals(false));
   } catch (error: any) {
    toast.error(error.response.data?.error?.message, toastOptions);
    dispatch(setisFetchingProfessionals(false));
   }
 }

 export const fetchProfessionalsExternalById = (id: string): AppThunk =>
  async (dispatch) => {
   const { setisFetchingProfessionals, setProfessionalExternoById } = professionalsSlice.actions;
   const response = await api.get(
     `/api/users-external/${id}`
   );
   dispatch(setProfessionalExternoById(response.data.data.users));
   dispatch(setisFetchingProfessionals(false));
 }


export const fetchProfessionalsByServiceCategoryData = (): AppThunk =>
 async (dispatch) => {
  const { setisFetchingProfessionals, setProfessionalsSelect } = professionalsSlice.actions;
  const response = await api.get(
    `api/users?profissional=true&page=1&limit=9999`
  );
  dispatch(setProfessionalsSelect(response.data.data.users));
  dispatch(setisFetchingProfessionals(false));
}
export const fetchProfessionalsByServiceCategory =
  (serviceCategoryId: string[]): AppThunk =>
  async (dispatch) => {
    const { setisFetchingProfessionals, setProfessionalsSelectOptions } = professionalsSlice.actions;
    dispatch(setisFetchingProfessionals(true));
    const isValidServiceCategoryId = serviceCategoryId.every((id) => id.length > 0);

    if (isValidServiceCategoryId) {
      try {
        const response = await api.get(
          `/api/users/category${queryStringFromFilterArray([{ key: "categoryIds", value: serviceCategoryId }]).replace(
            "&",
            "?"
          )}`
        );
        dispatch(setProfessionalsSelectOptions(response.data.data));
        dispatch(setisFetchingProfessionals(false));
      } catch (error: any) {
        dispatch(setisFetchingProfessionals(false));
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      }
    } else {
      toast.error("Não foi possível filtrar os profissionais", toastOptions);
      dispatch(setisFetchingProfessionals(false));
    }
  };

export const fetchProfessionalServiceCategories =
  (professionalId: string, scheduleId?: string): AppThunk =>
  async (dispatch) => {
    const { setisFetchingProfessionalServiceCategories, setProfessionalServiceCategoriesOptions } =
      professionalsSlice.actions;
    dispatch(setisFetchingProfessionalServiceCategories(true));
    try {
      
      const response = await api.get(`/api/categories/users?userIds=${professionalId}${scheduleId ? "&scheduleIds="+scheduleId : "" }`);
      dispatch(setProfessionalServiceCategoriesOptions(response.data.data));
      dispatch(setisFetchingProfessionalServiceCategories(false));
    } catch (error: any) {
      dispatch(setisFetchingProfessionalServiceCategories(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const { setProfessionalsFilteredByItem, setProfessionalsExterno } = professionalsSlice.actions;

export default professionalsSlice.reducer;
