import { IFilter } from "models/shared";

export function transformQueryKeys(filterArray: IFilter[], keyMap: any) {
  const newFilter: IFilter[] = [];

  filterArray.forEach(filter_item => {
    if (Object.keys(keyMap).some(key_mapped => key_mapped === filter_item.key)) {
      newFilter.push({
        ...filter_item,
        key: keyMap[filter_item.key]
      })
    } else {
      newFilter.push(filter_item)
    }
  })

  return newFilter;
}